import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Accordion,
  As,
  Text,
} from '@chakra-ui/react';
import { CaretCircleDown } from '@phosphor-icons/react';
import { FC } from 'react';
import { NavPost } from '@/types';
import { arrayShift, NavList } from '../utils';
import LinkItem from './LinkItem';

const ControlledFragment: FC = ({ children }) => <>{children}</>;

const MobileMenuItem = ({
  title,
  children,
  ID,
  as: itemAs,
  child,
  onNavigate,
  defaultIndexes,
  anyExpanded,
}: NavPost & {
  as: As;
  child?: boolean;
  onNavigate: () => void;
  defaultIndexes?: number[];
  anyExpanded?: boolean;
}) => {
  const isList = (children?.length || 0) > 1;
  const ItemTitle = child ? 'h3' : 'h2';
  const hasGrandChildren =
    isList && !!(children && children[0].children?.length);
  let resolvedAccordionIndex = -1;

  const isFinalLevelChildren = !children?.some(({ children: c }) => c);
  return (
    <AccordionItem as={itemAs} border="none" transitionDuration="250ms">
      <ItemTitle>
        <Text
          as={AccordionButton}
          fontSize="1rem"
          fontWeight="600"
          justifyContent="space-between"
          sx={{
            '& svg': {
              transition: 'transform .15s ease-in',
            },
          }}
          _expanded={{ '& svg': { transform: 'rotate(-180deg)' } }}
        >
          {title}
          <CaretCircleDown
            size="20"
            color="var(--chakra-colors-highlightColor)"
          />
        </Text>
      </ItemTitle>
      <AccordionPanel
        as={isList ? NavList : 'div'}
        id={`main-menu-sub-nav-${ID || title || ''}`}
        {...(isList
          ? {
              pt: !hasGrandChildren ? 4 : 0,
            }
          : null)}
      >
        <Accordion
          allowToggle
          as={ControlledFragment}
          variant="nav"
          defaultIndex={defaultIndexes ? defaultIndexes[0] : undefined}
        >
          {children?.map((item) => {
            if (item.children || isFinalLevelChildren) {
              resolvedAccordionIndex += 1;
            }

            const isSelected =
              defaultIndexes && defaultIndexes[0] === resolvedAccordionIndex;

            return item.children ? (
              <MobileMenuItem
                {...item}
                key={item.ID}
                as={isList ? itemAs : 'div'}
                onNavigate={onNavigate}
                defaultIndexes={
                  isSelected && defaultIndexes
                    ? arrayShift(defaultIndexes)
                    : undefined
                }
                child
                anyExpanded={anyExpanded}
              />
            ) : (
              <LinkItem
                {...item}
                as={isList ? itemAs : 'div'}
                key={item.ID}
                onNavigate={onNavigate}
                grandChild
              />
            );
          })}
        </Accordion>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default MobileMenuItem;
