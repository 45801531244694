import { As } from '@chakra-ui/react';
import { NavPost } from '@/types';
import Link from '../../Link';
import { mobileNavBreakpointKey, NavListItem } from '../utils';

const NavLinkItem = ({
  url,
  title,
  as,
  grandChild,
  onNavigate,
}: NavPost & { as?: As; grandChild?: boolean; onNavigate: () => void }) => (
  <NavListItem
    as={as}
    px={grandChild ? 4 : { base: 4, [mobileNavBreakpointKey]: 0 }}
  >
    <Link
      href={url}
      display="block"
      onClick={onNavigate}
      _activeLink={{
        base: {
          borderLeft: '4px solid var(--chakra-colors-highlightColor)',
          pointerEvents: 'none',
          pl: 'calc(var(--chakra-space-4) - 4px)',
          left: -4,
          position: 'relative',
        },
        [mobileNavBreakpointKey]: {
          borderLeft: 0,
          pl: 0,
          borderBottom: '3px solid var(--chakra-colors-highlightColor)',
          position: 'static',
        },
      }}
      {...(grandChild
        ? null
        : {
            py: 2,
            fontSize: '1rem',
            fontWeight: 600,
          })}
    >
      {title}
    </Link>
  </NavListItem>
);

export default NavLinkItem;
